/* eslint-disable prettier/prettier */
import type { AccessMatrixKey } from './features/AccessMatrix/types';

export type TMenuItem = {
  path: string;
  title: string;
  icon?: string;
  featureFlag?: string;
  accessKey?: AccessMatrixKey;
};
export type TMenuItemWithAccessKey = {
  path: string;
  title: string;
  icon?: string;
  featureFlag?: string;
  accessKey: AccessMatrixKey;
};
export type TDashboardState = { url: string; title: string; pageTitle?: string };
export type TMenuItemGroup = { title: string; icon: string; featureFlag?: string; sub: TMenuItem[] };
export type TMenuUser = { name: string; avatar: string };

const baseUrl = import.meta.env.VITE_TRF_DESKTOP_URL || 'http://trf.dev.skdf';

export const dashboards: TDashboardState[] = [
  {
    url: `${baseUrl}/dashboard/shared/19432116`,
    title: 'Расходы',
  },
  {
    url: `${baseUrl}/dashboard/shared/29200344`,
    title: 'Расходы субъектов',
  },
  {
    url: `${baseUrl}/dashboard/shared/25685446`,
    title: 'Доходы',
  },
  {
    url: `${baseUrl}/dashboard/shared/16453083`,
    title: 'Доходы субъектов',
  },
  {
    url: `${baseUrl}/dashboard/shared/26261448`,
    title: 'Дорожная сеть',
  },
  {
    url: `${baseUrl}/dashboard/shared/25696119`,
    title: 'Опорная сеть',
  },
  {
    url: `${baseUrl}/dashboard/shared/49639975`,
    title: 'Доп. информация',
  },
  // {
  //   url: `${baseUrl}/dashboard/shared/26023868`,
  //   title: 'Агломерации',
  // },
  // {
  //   url: `${baseUrl}/dashboard/shared/25685987`,
  //   title: 'Мероприятия',
  // },
  {
    url: `${baseUrl}/dashboard/40528194`,
    title: 'ГЭТ',
  },
];

export const dashboards2 = [
  { url: `${baseUrl}/dashboard/shared/36387304`, title: 'Статистические данные' },
  { url: `${baseUrl}/dashboard/shared/36387309`, title: 'Программы дорожных работ' },
];

export const menu1: (TMenuItem | TMenuItemGroup)[] = [
  { path: '/desktop', title: 'Рабочий стол', icon: 'app', accessKey: 'menu_desktop' },
  {
    title: 'Цифровая база',
    icon: 'folder',
    sub: [
      { path: '/roads', title: 'Дороги', icon: 'road', accessKey: 'menu_roads' },
      { path: '/bridges', title: 'Мостовые сооружения', icon: 'bridge_outline', accessKey: 'menu_bridges' },
      // { path: '/planning', title: 'Мероприятия', icon: 'calendar', accessKey: 'menu_events' },
      { path: '/traffic-accidents', title: 'ДТП', icon: 'car_outline', accessKey: 'menu_accidents' },
      { path: '/messages', title: 'Сообщения граждан', icon: 'e-mail', accessKey: 'menu_citizen_messages' },
      { path: '/map', title: 'Карта', icon: 'list_on_map', accessKey: 'menu_map' },
      {
        path: '/map-graph',
        title: 'Граф',
        icon: 'list_on_map',
        accessKey: 'menu_user_accounts',
        featureFlag: 'isGraphEnabled',
      },
      {
        path: '/map-valhalla/directions?profile=car',
        title: 'Маршруты',
        icon: 'list_on_map',
        accessKey: 'menu_user_accounts',
        featureFlag: 'isGraphEnabled',
      },
      {
        path: '/roadOwners',
        title: 'Собственники дорог',
        icon: 'group',
        accessKey: 'menu_road_owners',
      },
    ],
  },
  {
    title: 'Планирование',
    icon: 'planning',
    featureFlag: 'isPlanningEnabled',
    sub: [
      {
        path: `/planning/federal/programs?type=overhauls`,
        title: 'Федеральные программы',
        icon: 'federal',
        featureFlag: 'isPlanningConstructionEnabled',
        accessKey: 'menu_events_federal',
      },
      {
        path: `/planning/regional/programs`,
        title: 'Региональные программы',
        icon: 'regional',
        featureFlag: 'isPlanningConstructionEnabled',
        accessKey: 'menu_events_regional',
      },
      // {
      //   path: `/planning/work_program/`,
      //   title: 'Программа дорожной деятельности',
      //   icon: 'work_program',
      //   featureFlag: 'isPlanningConstructionEnabled',
      //   accessKey: 'menu_events',
      // },
    ],
  },
  {
    title: 'Проектная деятельность',
    icon: 'projects',
    sub: [
      {
        path: '/projects',
        title: 'Проекты',
        icon: 'project',
        featureFlag: 'isProjectsEnabled',
        accessKey: 'menu_projects',
      },
      {
        path: '/documents',
        title: 'Документы',
        icon: 'document',
        featureFlag: 'isDocumentsEnabled',
        accessKey: 'menu_documents',
      },
      {
        path: '/protocols',
        title: 'Протоколы',
        icon: 'protocool',
        featureFlag: 'isProtocolsEnabled',
        accessKey: 'menu_protocols',
      },
      { path: '/contacts', title: 'Контакты', icon: 'contact', accessKey: 'menu_contacts' },
    ],
  },
  {
    title: 'Закупки',
    icon: 'purchase',
    sub: [
      { path: '/contracts', title: 'Контракты/Договоры', icon: 'contract', accessKey: 'menu_contracts' },
    ],
  },
  {
    title: 'Аналитика',
    icon: 'chart',
    sub: dashboards.map((dashboard) => ({
      path: `/indicators/${dashboard.title}`,
      title: dashboard.title,
    })),
  },
  // /stats Статистика ...
  {
    path: '/database',
    title: 'Открытые данные',
    icon: 'database',
    featureFlag: 'isOpenDataEnabled',
    accessKey: 'menu_open_data',
  },
  { path: '/dashboards/Программы дорожных работ', title: 'Программы работ', icon: 'work_program' },
  {
    title: 'Формы отчетности',
    icon: 'report',
    sub: [
      { path: '/dashboards/Статистические данные', title: 'Статистические данные', icon: 'report' },
      {
        path: '/statforms/615060',
        title: '7-Автодор',
        icon: 'report',
        accessKey: 'menu_reporting_form_7_autodor',
        featureFlag: 'isGeogracomReportsEnabled',
      },
      {
        path: '/statforms/615064',
        title: '1-ДГ',
        icon: 'report',
        accessKey: 'menu_reporting_form_1_dg',
        featureFlag: 'isGeogracomReportsEnabled',
      },
      {
        path: '/statforms/99615064',
        title: '1-ДГ (РФ)',
        icon: 'report',
        accessKey: 'menu_reporting_form_1_dg_rf',
        featureFlag: 'isGeogracomReportsEnabled',
      },
      {
        path: '/statforms/615063',
        title: '2-ДГ',
        icon: 'report',
        accessKey: 'menu_reporting_form_2_dg',
        featureFlag: 'isGeogracomReportsEnabled',
      },
      {
        path: '/statforms/601028',
        title: '1-ФД',
        icon: 'report',
        accessKey: 'menu_reporting_form_1_fd',
        featureFlag: 'isGeogracomReportsEnabled',
      },
      {
        path: '/statforms/9900200',
        title: 'Стоимость 1 километра',
        icon: 'report',
        accessKey: 'menu_reporting_form_1_km_price',
        featureFlag: 'isGeogracomReportsEnabled',
      },
      {
        path: '/statforms/9900100',
        title: 'Мониторинг дорожных фондов',
        icon: 'report',
        accessKey: 'menu_reporting_form_monitoring',
        featureFlag: 'isGeogracomReportsEnabled',
      },
      {
        path: '/statforms/999900100',
        title: 'Мониторинг (сводная, факт)',
        icon: 'report',
        accessKey: 'menu_reporting_form_monitoring',
        featureFlag: 'isGeogracomReportsEnabled',
      },
      {
        path: '/statforms/98615064',
        title: '1-2 ДГ (сводная, субъекты)',
        icon: 'report',
        accessKey: 'menu_reporting_form_1_dg_2dg_subject',
        featureFlag: 'isGeogracomReportsEnabled',
      },
      {
        path: '/statforms/97615064',
        title: '1-2 ДГ (сводная, ФКУ)',
        icon: 'report',
        accessKey: 'menu_reporting_form_1_dg_2dg_fku',
        featureFlag: 'isGeogracomReportsEnabled',
      },
 {
        path: '/statforms/99601028',
        title: '1-ФД (сводная, результаты)',
        icon: 'report',
        accessKey: 'menu_reporting_form_1_fd_res',
        featureFlag: 'isGeogracomReportsEnabled',
      },
      {
        path: '/statforms/98601028',
        title: '1-ФД (сводная, использование)',
        icon: 'report',
        accessKey: 'menu_reporting_form_1_fd_use',
        featureFlag: 'isGeogracomReportsEnabled',
      },
      {
        path: '/statforms/989900100',
        title: 'Мониторинг (сводная, план)',
        icon: 'report',
        accessKey: 'menu_reporting_form_monitoring_plan',
        featureFlag: 'isGeogracomReportsEnabled',
      },
      {
        path: '/statforms/979900100',
        title: 'Мониторинг (сводная, буд)',
        icon: 'report',
        accessKey: 'menu_reporting_form_monitoring_future',
        featureFlag: 'isGeogracomReportsEnabled',
      },
      {
        path: '/statforms/615117',
        title: '1-БКАД (архив)',
        icon: 'report',
        accessKey: 'menu_reporting_form_1_bkad',
        featureFlag: 'isGeogracomReportsEnabled',
      },
      {
        path: '/statforms/615058',
        title: '3-Автодор (архив)',
        icon: 'report',
        accessKey: 'menu_reporting_form_3_autodor',
        featureFlag: 'isGeogracomReportsEnabled',
      },
    ],
  },
];

export const menu2: (TMenuItem | TMenuItemGroup)[] = [
  {
    path: '/notifications',
    title: 'Уведомления',
    icon: 'bell',
    featureFlag: 'isNotificationsEnabled',
    accessKey: 'menu_notifications',
  },
  { path: '/help', title: 'Справочный центр', icon: 'question' },
  {
    title: 'Администрирование',
    icon: 'setting_baby',
    accessKey: 'menu_administration',
    sub: [
      {
        path: '/administration/nsi',
        title: 'Нормативно-справочная информация',
        icon: 'info',
        featureFlag: 'isNSIEnabled',
        accessKey: 'menu_nsi',
      },
      {
        path: '/administration/import',
        title: 'Загрузка данных',
        icon: 'upload',
        featureFlag: 'isDataImportEnabled',
        accessKey: 'menu_data_import',
      },
      {
        path: '/administration/users',
        title: 'Пользователи',
        icon: 'group',
        featureFlag: 'isUsersEnabled',
        accessKey: 'menu_user_accounts',
      },
      {
        path: '/administration/newsletter',
        title: 'Сервис рассылки',
        icon: 'e-mail',
        featureFlag: 'isNewsletterEnabled',
        accessKey: 'menu_nsi',
      },
      {
        path: '/administration/statforms',
        title: 'Формы отчётности',
        icon: 'report',
        featureFlag: 'isGeogracomReportsEnabled',
        accessKey: 'menu_administration',
      },
    ],
  },
];
