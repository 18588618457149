import client from '@/planning/regional/httpClient';
import { downloadBlobFile } from '@/utils';

import type {
  SearchContractsResponse,
  SearchParams,
  SearchPaymentsResponse,
  TContract,
  UserOrgResponse,
} from './types';
import type { TPayment } from '@/planning/regional/events/EventPage/types';

export const planningUrl = 'planning';
export const regionalPlanningUrl = `${planningUrl}/regional`;

export const defaultPagination = {
  limit: 100,
  offset: 0,
};

export async function fetchUserOrgRegion() {
  const eventsTableResponse = await client.get<UserOrgResponse>(`${planningUrl}/nsi/user_org_region`);
  return eventsTableResponse.data.data;
}

export async function getFileBlob(id: string) {
  const response = await client.get<{ data: Blob }>(`planning/files/download/${id}`, {
    responseType: 'blob',
  });
  return response.data;
}

export async function downloadFile(id: string, name: string): Promise<void> {
  await client
    .get<Blob>(`planning/files/download/${id}`, {
      responseType: 'blob',
    })
    .then((value) => value && downloadBlobFile(value.data, name));
}

export type UserRole = {
  id: number;
  name: string;
  key:
    | 'program_operator'
    | 'events_operator'
    | 'program_coordinator'
    | 'agregate_program_coordinator'
    | 'extended_program_coordinator'
    | 'signatory'
    | 'superadmin'
    | 'director';
};

export type CurrentUserRolesResponse = {
  user_id: number;
  roles: UserRole[];
};

export async function fetchCurrentUserRoles() {
  const response = await client.get<CurrentUserRolesResponse>('/planning/regional/current_user_roles');
  return response.data;
}

export async function getContractById(id: number | string) {
  const response = await client.get<TContract>(`${regionalPlanningUrl}/events/contracts/${id}`);
  return response.data;
}

export async function searchContracts({ search, limit, offset }: SearchParams) {
  const pagination: Record<string, string> = {};
  if (limit || offset) Object.assign(pagination, { limit, offset });

  const requestParams = limit || offset ? '?' + new URLSearchParams(pagination).toString() : '';

  const response = await client.post<SearchContractsResponse>(
    `${regionalPlanningUrl}/search_contracts${requestParams}`,
    { search: search }
  );
  return response.data;
}

export async function getPaymentById(id: number | string) {
  const response = await client.get<TPayment>(`${regionalPlanningUrl}/events/contracts/payments/${id}`);
  return response.data;
}

export async function searchPayments(contractId: number | string, params: SearchParams) {
  const requestParams = params && Object.keys(params)?.length ? '?' + new URLSearchParams(params).toString() : '';

  const response = await client.get<SearchPaymentsResponse>(
    `${regionalPlanningUrl}/search_payments/${contractId}${requestParams}`
  );
  return response.data;
}
