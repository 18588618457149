import { observer } from 'mobx-react-lite';

import Modal from '@/components/Modal';
import Button from '@/components/Button';

import store from './store';
import classNames from 'classnames';
import SkdfIcon from '@/components/SkdfIcon';

function MultiStepModal() {
  const {
    isOpen,
    close,
    currentStep,
    prevStepHandler,
    nextStepHandler,
    steps,
    title,
    modalProps,
    hideCloseBtn,
    step,
    hasBreadCrumbs,
  } = store;

  if (!steps || !steps.length || currentStep === null || currentStep === undefined) return null;

  return (
    <Modal
      show={isOpen}
      size="xl"
      scrollable={false}
      onHide={close}
      contentClassName="overflow-visible"
      {...modalProps}
    >
      <Modal.Header>
        <Modal.Title>
          <div className="h2">{step?.title || title}</div>
          <div className="d-flex align-items-center">
            {hasBreadCrumbs &&
              steps.map((step, index) => (
                <span
                  key={index}
                  className={classNames('d-flex align-items-center', {
                    'text-muted': currentStep !== index,
                  })}
                >
                  <span className="small">{step.breadCrumb}</span>
                  {steps.length - 1 !== index && (
                    <SkdfIcon
                      name="small_down"
                      style={{ width: '12px', height: '12px', transform: 'rotate(-90deg)' }}
                      className="text-muted mx-2"
                    />
                  )}
                </span>
              ))}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-visible">
        <div>{step?.component}</div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <div className="d-flex">
          {!hideCloseBtn && (
            <Button variant="function" className="text-start inline-block" onClick={close}>
              Отменить
            </Button>
          )}
        </div>
        <div>
          <Button onClick={prevStepHandler} variant="secondary" className="me-2">
            {step?.prevBtn?.label || 'Назад'}
          </Button>
          <Button onClick={nextStepHandler} disabled={step?.nextBtn?.isDisabled}>
            {step?.nextBtn?.label || 'Сохранить'}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default observer(MultiStepModal);
